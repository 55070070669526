import noop from '@utils/noop';
import throttle from '@utils/throttle';
import { ClassesMapper } from '@xo-union/react-css-modules';
import Modal, {
	ModalProps,
	FullscreenModal,
	FullscreenModalClasses,
} from '@xo-union/tk-component-modals';
import { ModalContainer } from '@xo-union/ui-accessibility';
import React, { useCallback, useEffect } from 'react';
import { FCWithChildren } from 'types/react-extended';
import { ANDROID_CLOSING_METHOD_NAME } from '../../../constants/android_proxy';

export const MODAL_TYPES = {
	NORMAL: 'normal',
	FULL_SCREEN: 'fullscreen',
	CONTAINER: 'container',
};

export const NAVIGABLE_MODAL_THROTTLE_TIME = 200;

const androidProxy = window.androidAppTKPlannerProxy;
let previousAndroidProxyCloseMethod = noop;

const handleCloseModalAndroid = () => {
	if (androidProxy) {
		androidProxy.onCloseModal();
		androidProxy[ANDROID_CLOSING_METHOD_NAME] = previousAndroidProxyCloseMethod;
	}
};

export const closeNavigableModal = throttle(
	(closeCallback: Function, hash: string) => {
		if (window.location.hash === `#${hash}`) {
			window.history.back();
		}
		handleCloseModalAndroid();
		closeCallback?.();
	},
	NAVIGABLE_MODAL_THROTTLE_TIME,
);

export interface NavigableModalProps extends ModalProps {
	hash: string;
	isModalOpen: boolean;
	closeCallback: Function;
	children?: React.ReactNode;
	type?: string;
	isModalOnModal?: boolean; // pass this property if the modal is opened on another modal
	fullscreenModalClasses?: ClassesMapper<FullscreenModalClasses>; // temporary extension to pass separate classes for FullscreenModal
}

let modalOnModalHash = '';

const NavigableModal: FCWithChildren<NavigableModalProps> = ({
	hash = 'modal-open',
	isModalOpen = false,
	closeCallback = noop,
	type = MODAL_TYPES.NORMAL,
	isModalOnModal = false,
	classes,
	fullscreenModalClasses,
	...props
}) => {
	const callCloseNavigableModal = useCallback(() => {
		closeNavigableModal(closeCallback, hash);
	}, [hash, closeCallback]);

	const shouldCloseModalOnModal = (): boolean => {
		return (
			modalOnModalHash === hash &&
			`#${modalOnModalHash}` !== window.location.hash
		);
	};

	const notifyAndroidAppOfModalOpening = () => {
		if (androidProxy) {
			previousAndroidProxyCloseMethod =
				androidProxy[ANDROID_CLOSING_METHOD_NAME] || noop;
			androidProxy[ANDROID_CLOSING_METHOD_NAME] =
				closeNavigableModal.bind(this);
			androidProxy.onModalViewOpened?.(
				Buffer.from(
					`window.androidAppTKPlannerProxy.${ANDROID_CLOSING_METHOD_NAME}()`,
					'utf8',
				).toString('base64'),
			);
		}
	};

	const openNavigableModal = useCallback(
		throttle(() => {
			window.location.href = `${window.location.href.split('#')[0]}#${hash}`;
			notifyAndroidAppOfModalOpening();
		}, NAVIGABLE_MODAL_THROTTLE_TIME),
		[],
	);

	useEffect(() => {
		if (isModalOpen && window.location.hash !== `#${hash}`) {
			openNavigableModal();
			isModalOnModal && (modalOnModalHash = hash);
		} else if (!isModalOpen) {
			callCloseNavigableModal();
		}
	}, [isModalOpen]);

	const hashChanged = useCallback(() => {
		if (
			isModalOpen &&
			(window.location.hash === '' || shouldCloseModalOnModal())
		) {
			callCloseNavigableModal();
		}
	}, [hash, isModalOpen]);

	useEffect(() => {
		window.addEventListener('hashchange', hashChanged);
		return () => {
			window.removeEventListener('hashchange', hashChanged);
		};
	}, [hashChanged]);

	if (!isModalOpen) return null;

	if (type === MODAL_TYPES.FULL_SCREEN) {
		return (
			<FullscreenModal
				onClose={callCloseNavigableModal}
				classes={fullscreenModalClasses}
				{...props}
			>
				{props.children}
			</FullscreenModal>
		);
	}

	if (type === MODAL_TYPES.CONTAINER) {
		return (
			// @ts-expect-error since we're extending from ModalProps the classes type is different
			<ModalContainer onEscapeKey={callCloseNavigableModal} {...props}>
				{props.children}
			</ModalContainer>
		);
	}

	return (
		<Modal
			onCloseButtonClick={callCloseNavigableModal}
			onOverlayClick={callCloseNavigableModal}
			{...props}
			classes={classes}
		>
			{props.children}
		</Modal>
	);
};

export default NavigableModal;
